import { Injectable } from '@angular/core';
import { DataService } from './dataservice';

interface ConversionResults {
   content: string; width: number; height: number 
}

@Injectable()
export class FileReaderService {
  constructor(private dataService: DataService) {}

  public fileReadPromise(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reader.abort();
        reject('could not read file');
      };
      reader.readAsDataURL(file);
    });
  }

  public emitConvertedImages(
    file: File,
    fileReadPromise: Promise<string>
  ): Promise<void> {
    const promiseArray: [Promise<ConversionResults>, Promise<string>] = [
      this.dataService.convertImage(file), // 2. request conversion from server
      fileReadPromise,
    ];
    return Promise.all(promiseArray).then(results => {
      // get both images as data-uri
      const convertedImagInfo = results[0];
      const originalImageContent = results[1];

      if (
        convertedImagInfo &&
        convertedImagInfo.content &&
        convertedImagInfo.content !== ''
      ) {
        return this.dataService.imageConvertedEmitter.emit({
          convertedImage: convertedImagInfo.content,
          originalImage: {
            content: originalImageContent,
            originalWidth: convertedImagInfo.width,
            originalHeight: convertedImagInfo.height,
          },
          file,
        });
      }
      return Promise.resolve();
    });
  }
}
